<template>
    <div class="approvals-list">
        <form v-if="0 < items.length" @submit.prevent="submit">
            <approvals-list-header :editable-fields="editableFields"/>

            <approvals-list-item v-for="(approval, index) in items"
                                 v-model="items[index].model"
                                 :key="index"
                                 :approval="approval"
                                 :config="config"
                                 :editable-fields="editableFields"
                                 :static-fields="staticFields"
                                 :disabled="processing"/>

            <div class="form-group text-center">
                {{t('review')}}
            </div>

            <!-- Submit -->
            <div class="text-center pt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="processing || invalid || nochanges">
                    <span v-if="true === processing">
                        <spinner class="mr-1"/>
                        <span>{{t('cta.submit.processing')}}</span>
                    </span>
                    <span v-else>{{t('cta.submit.text')}}</span>
                </button>
            </div>
        </form>

        <div v-else-if="0 === items.length" class="text-center">
            <div class="pb-3">{{t('empty')}}</div>

            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </div>
    </div>
</template>

<script>

    import {get} from "lodash";

    import ApprovalsListHeader from "@/components/approvals/list/ApprovalsListHeader.vue";
    import ApprovalsListItem from "@/components/approvals/list/ApprovalsListItem.vue";
    import Spinner from "@/components/Spinner.vue";

    const TRANSLATIONS = ["label", "placeholder"]; // Field properties to translate

    export default {
        name: "ApprovalsList",
        emits: ["refresh"],
        components: {
            Spinner,
            ApprovalsListItem,
            ApprovalsListHeader
        },
        props: {
            approvals: {
                type: Array,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list",
                processing: false,
                items: []
            };
        },
        computed: {
            config() {

                return this.$store.getters["user/approval/config"].map(
                    field => {

                        // Handle translations
                        TRANSLATIONS
                            .filter(property => true === this.$te(`approval_config.${field.payload.field}.${property}`))
                            .forEach(property => field[property] = this.$t(`approval_config.${field.payload.field}.${property}`));

                        // Handle errors and translations
                        field.errors = Object
                            .keys("undefined" !== typeof field.validation ? field.validation : {})
                            .filter(property => true === this.$te(`approval_config.${field.payload.field}.errors.${property}`))
                            .reduce(
                                (errors, property) => ({
                                    ...errors,
                                    [property]: this.$t(`approval_config.${field.payload.field}.errors.${property}`)
                                }),
                                {}
                            );

                        return field;
                    }
                );
            },
            editableFields() {

                return this.config.filter(field => true === field.editable);
            },
            staticFields() {

                return this.config.filter(field => true !== field.editable);
            },
            invalid() {

                return this.items.reduce(
                    (invalid, item) => true === invalid || false === item.model.valid,
                    false
                )
            },
            nochanges() {

                return this.items.reduce(
                    (nochanges, item) => false === nochanges ? nochanges : null === item.model.approved,
                    true
                );
            }
        },
        methods: {
            submit() {

                const approvals = this.items.filter(item => null !== item.model.approved);

                if (approvals.length !== this.items.length) {

                    return this
                        .$modal
                        .confirm(this.t("confirm.partial"))
                        .then(() => this.process(), () => this.processing = false);
                }

                return this.process();
            },
            process() {

                this.processing = true;

                const request = this.items
                    .filter(item => null !== item.model.approved)
                    .reduce(
                        (request, item) => ({
                            ...request,
                            [item.order.id]: {
                                approved: item.model.approved,
                                reason: item.model.reason,
                                // Collect dynamic fields
                                ...this.editableFields.reduce(
                                    (result, field) => ({
                                        ...result,
                                        [field.payload.field]: item.model[field.payload.field]
                                    }),
                                    {}
                                )
                            }
                        }),
                        {}
                    );


                this.$order
                    .post(`/users/${this.$store.getters["user/id"]}/approvals`, request)
                    .then(() => this.$store.dispatch("user/surveys/fetch"))
                    .then(() => {

                        this.$emit("refresh");

                        this.$modal
                            .message(this.t("saved"))
                            .then(() => true, () => true);
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false)
            },
            init() {

                this.items.splice(
                    0,
                    this.items.length,
                    ...this.approvals
                        .reduce(
                            (result, item) => {

                                result.push({
                                    order: {
                                        id: item.order_id,
                                        cost: item.cost,
                                        currency: item.currency,
                                        country: item.country,
                                        date: new Date(item.date),
                                        lang_overrides: item.lang_overrides,
                                        details: item.details,
                                        requested_by: get(item, "requested_by", null)
                                    },
                                    transfer: {
                                        enabled: false
                                    },
                                    user: {
                                        id: item.user_id,
                                        name: item.name,
                                    },
                                    approval: {
                                        required: true !== item.approval_not_required,
                                        reason: get(item, "approval.approval_reason", null),
                                        manager: {
                                            email: get(item, "approval.assigned_approver")
                                        },
                                        approver: {
                                            // Nobody is here because order is pending
                                            email: null
                                        }
                                    },
                                    dynamic: {
                                        ...this.config.reduce(
                                            (result, field) => ({
                                                ...result,
                                                [field.payload.field]: item[field.payload.field]
                                            }),
                                            {}
                                        )
                                    },
                                    model: {
                                        valid: true, // By default, valid is true
                                        approved: null,
                                        reason: null,
                                        // Append dynamic fields
                                        ...this.config.reduce(
                                            (result, field) => {

                                                if (true === field.editable || "undefined" !== typeof field.watch) {

                                                    result[field.payload.field] = item[field.payload.field];
                                                }

                                                return result;
                                            },
                                            {}
                                        )
                                    }
                                });

                                return result;
                            },
                            []
                        )
                        .sort((a, b) => a.order.date.getTime() - b.order.date.getTime())
                );
            }
        },
        mounted() {

            this.init();
        }
    }
</script>