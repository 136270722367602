export default {
    ordered: {
        type: {
            "ac_router": "Já encomendei um roteador AC diferente",
            "chair": "Já encomendei uma cadeira diferente",
            "default": "Já encomendado",
            "default_multiple": "Já encomendou produtos semelhantes",
            "desk": "Já encomendei uma mesa diferente",
            "desktop": "Já encomendei um desktop diferente",
            "desktop_riser": "Já encomendei um suporte de mesa diferente",
            "detector": "Já encomendei diferentes detectores de vapor e ruído",
            "displayport_cable": "Já encomendei um cabo displayport diferente",
            "docking station": "Já encomendei uma estação de encaixe diferente",
            "dual_usb_adapter": "Já encomendei um adaptador USB dual band diferente",
            "ear cushion": "Já encomendei uma almofada de ouvido diferente",
            "footrest": "Já encomendei um apoio para os pés diferente",
            "hdmi_cable": "Já encomendei cabos HDMI diferentes",
            "hdmi_displayport_cable": "Já encomendei um cabo HDMI para DisplayPort diferente",
            "hdmi_vga_cable": "Já encomendei um cabo HDMI para VGA diferente",
            "headset": "Já encomendou um dispositivo de áudio diferente",
            "keyboard": "Já encomendei um teclado diferente",
            "kvm switch": "Já encomendei um switch kvm diferente",
            "kvm_switch": "Já encomendei um switch kvm diferente",
            "lamp": "Já encomendei uma lâmpada diferente",
            "laptop stand": "Já encomendei um suporte para laptop diferente",
            "laptop": "Já encomendei um laptop diferente",
            "mesh_router": "Já encomendei um roteador mesh diferente",
            "monitor": "Já encomendei um monitor diferente",
            "mouse": "Já encomendei ratos diferentes",
            "patch_cord": "Já encomendei um patch cord cat6 diferente",
            "power_center": "Já encomendei um Power Center diferente",
            "power_strip": "Já encomendei um filtro de linha diferente",
            "smart_plug": "Já encomendei diferentes mini plugues inteligentes",
            "smart_power_strip": "Já encomendei diferentes filtros de linha inteligentes",
            "thunderbolt_cable": "Já encomendei um cabo USB-C para Thunderbolt diferente",
            "travel_router": "Já encomendei um roteador de viagem diferente",
            "usb hub": "Já encomendei um hub USB diferente",
            "usb_adapter": "Já encomendei um adaptador USB diferente",
            "usb_hub": "Já encomendei um hub diferente",
            "webcam": "Já encomendei uma webcam diferente"
        },
        product: "Ordenado"
    },
    request: "Solicitar",
    add: "Adicionar ao carrinho",
    remove: "Remover do carrinho",
    added: "Adicionado ao carrinho",
    view: "Ver item"
}