import {createStore} from 'vuex'
import user from "./user";
import breadcrumbs from "./breadcrumbs";
import db from "./db";
import widget from "./widget";
import i18n from "./i18n";
import modals from "./modals";
import picker from "./picker";
import grouping from "./grouping";

export default createStore({
    state: {
        partnerId: process.env.VUE_APP_PARTNER_ID
    },
    getters: {
        partnerId: state => state.partnerId
    },
    mutations: {},
    actions: {},
    modules: {
        user,
        breadcrumbs,
        db,
        widget,
        i18n,
        modals,
        picker,
        grouping
    }
})
