export default {
    title: {
        new: "Adicionar novo endereço",
        complete: "Endereço completo",
        edit: "Editar Endereço"
    },

    incomplete: "Por favor preencha os campos de endereço obrigatórios",

    info: {
        "CHN": "Por favor, digite seu endereço em chinês.</br> Por favor, digite seu endereço em chinês.",
        "BGR": "Digite seu <b>endereço residencial</b> em inglês para receber seus itens.",
        "PRI": "Por favor, insira seu endereço residencial e não uma caixa postal para receber pacotes.",
        "SGP": "Por favor inclua o número da sua unidade no seu endereço.",
        "SVK": "Por favor insira seu endereço em <b>inglês</b>",
        "POL": "Por favor insira seu endereço em <b>inglês</b>"
    },

    model: {
        country: {
            label: "País"
        }
    },

    field: {
        default: {
            name: {
                label: {Name: "Nome"},
                placeholder: {Name: "Nome"}
            },
            personalEmail: {
                label: {"Personal Email": "Email pessoal"},
                placeholder: {"Personal Email": "Email pessoal"}
            },
            managerEmail: {
                label: {"Manager Email": "E-mail do gerente"},
                placeholder: {"Manager Email": "E-mail do gerente"}
            },
            managerName: {
                label: {"Manager Name": "Nome do gerente"},
                placeholder: {"Manager Name": "Nome do gerente"}
            },
            postalCode: {
                label: {
                    Zipcode: "CEP",
                    "Postal Code": "Código postal"
                },
                placeholder: {
                    Zipcode: "CEP",
                    "Postal Code": "Código postal"
                }
            },
            province: {
                label: {"Province Code": "Código da Província"},
                placeholder: {"Province Code": "Código da Província"}
            },
            phoneNumber: {
                label: {"Phone Number": "Número de telefone"},
                placeholder: {"Phone Number": "Número de telefone"}
            },
            firstStreet: {
                label: {"Street": "Rua"},
                placeholder: {"Street": "Rua"}
            },
            secondStreet: {
                label: {"Street": "Rua"},
                placeholder: {"Street": "Rua"}
            },
            city: {
                label: {"City": "Cidade"},
                placeholder: {"City": "Cidade"}
            },
            identifier: {
                label: {"identifier": "Identificador"},
                placeholder: {"identifier": "Identificador"}
            },
            state: {
                label: {"State": "Estado",},
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alasca",
                    "Arizona": "Arizona",
                    "Arkansas": "Arcansas",
                    "California": "Califórnia",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Distrito da Colombia",
                    "Florida": "Flórida",
                    "Georgia": "Geórgia",
                    "Hawaii": "Havaí",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Luisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachussets",
                    "Michigan": "Michigan",
                    "Minnesota": "Minesota",
                    "Mississippi": "Mississipi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebrasca",
                    "Nevada": "Nevada",
                    "New Hampshire": "Nova Hampshire",
                    "New Jersey": "Nova Jersey",
                    "New Mexico": "Novo México",
                    "New York": "Nova Iorque",
                    "North Carolina": "Carolina do Norte",
                    "North Dakota": "Dakota do Norte",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Óregon",
                    "Pennsylvania": "Pensilvânia",
                    "Rhode Island": "Ilha de Rodes",
                    "South Carolina": "Carolina do Sul",
                    "South Dakota": "Dakota do Sul",
                    "Tennessee": "Tenessi",
                    "Texas": "Texas",
                    "Utah": "Utá",
                    "Vermont": "Vermonte",
                    "Virginia": "Virgínia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            deliveryInstructions: {
                label: {"Delivery Instructions": "Instruções de entrega",},
                placeholder: {"Extra address information, delivery instructions, etc": "Informações extras de endereço, instruções de entrega, etc.",}
            }
        },
        BRA: {
            name: {
                label: {"Full name": "Nome completo"},
                placeholder: {"Full name": "Nome completo"}
            },
            firstStreet: {
                label: {"Address 1": "Endereço 1"},
                placeholder: {"Address 1": "Rua + número da casa/apto"}
            },
            secondStreet: {
                label: {"Address 2": "Endereço 2"},
                placeholder: {"Address 2": "Rua + número da casa/apto"}
            },
            city: {
                label: {City: "Cidade"},
                placeholder: {City: "Cidade"},
            },
            state: {
                label: {State: "Estado"},
                placeholder: {State: "Estado"}
            },
            postalCode: {
                label: {"Zip code": "CEP"},
                placeholder: {"Zip code": "CEP"}
            },
            identifier: {
                label: {CPF: "CPF"},
                placeholder: {CPF: "CPF"}
            }
        }
    },

    errors: {
        required: "{field} é necessário.",
        mask: "{field} é inválido."
    },

    buttons: {
        submit: {
            text: "Salvar",
            saving: "Salvando"
        },
        cancel: {
            text: "Cancelar"
        }
    },

    disclaimer: "Adicionar este endereço exigirá verificação de e-mail. Depois de salvar o endereço, verifique seu e-mail e clique no link dentro dele.",

    message: {
        added: "O endereço foi adicionado",
        updated: "O endereço foi atualizado"
    }
}