export default {
    empty: "Nada a aprovar",
    saved: "Aprovações enviadas",
    review: "",
    confirm: {
        partial: "Ainda há pedidos com aprovações pendentes. Deseja continuar com suas alterações atuais?"
    },
    header: {
        name: "Nome",
        order: "Ordem",
        details: "Detalhes",
        cta: "Aprovação",
        message: ""
    },
    item: {
        order: {
            manager: "Gerente",
            requested: "Solicitado por"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "razão",
                    approved: "opcional; justificativa comercial",
                    notapproved: "razão pela qual o pedido foi negado"
                }
            },
            approve: {
                yes: {
                    label: "Sim"
                },
                no: {
                    label: "Não"
                }
            }
        },
        prior: {
            cta: {
                load: "Aprovações prévias",
                loading: "Carregando",
                show: "Aprovações prévias",
                hide: "Aprovações prévias"
            },
            approvals: {
                empty: "Nenhuma aprovação prévia",
                item: {
                    status: {
                        approver: "por {approver}",
                        system: "Central de Ajuda",
                        approved: "Aprovado",
                        rejected: "Não aprovado",
                        unrequired: "Aprovação não necessária",
                        reason: "Razão",
                        transferred: "Transferido"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Enviar",
            processing: "Enviando"
        }
    }
}